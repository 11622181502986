import {
  ConsumerClientAppClaim,
  ConsumerClientAppInput,
  ContributorMiniAppClaim,
  ContributorMiniAppInput,
  OrganisationInput,
  Organization,
  OtpVerificationInput,
} from 'types';

import httpClient from './httpClient';

function sanitizeString(value: string | undefined) {
  value = value?.trim();

  return value === '' ? undefined : value;
}

function stripEmptyStringAttributes(input: Record<string, any>) {
  let obj = { ...input };
  Object.keys(obj).forEach(key => {
    let value = obj[key];
    if (Array.isArray(value)) {
      value = value.map(sanitizeString).filter(Boolean);
    } else if (typeof value === 'object') {
      value = stripEmptyStringAttributes(value);
    } else if (typeof value === 'string') {
      value = sanitizeString(value);
    }

    obj[key] = value;

    if ((Array.isArray(value) || typeof value === 'string') && !value.length) {
      delete obj[key];
    }
  });

  return obj;
}

function splitLn(s?: string) {
  return s?.split('\n').map(sanitizeString).filter(Boolean);
}

export async function fetchConsumerClientAppScopes() {
  const payload = await httpClient('scopes');
  return payload;
}

export async function fetchOrganization(): Promise<Organization> {
  const payload = await httpClient('dev-orgs');
  return payload.items.pop();
}

export async function addOrganization(
  formData: OrganisationInput,
): Promise<void> {
  delete formData.agreeTerm;
  const payload = await httpClient('dev-orgs', {
    method: 'post',
    body: JSON.stringify(formData),
  });
  return payload;
}

export async function verifyOrganization(
  formData: OtpVerificationInput,
): Promise<Organization> {
  const payload = await httpClient('dev-orgs/verify', {
    method: 'post',
    body: JSON.stringify(formData),
  });
  return payload;
}

export async function addConsumerClientApp(
  organizationId: string,
  data: ConsumerClientAppInput,
): Promise<ConsumerClientAppClaim> {
  const formData = {
    ...transformClientAppFormData(data),
    pk: organizationId,
  };
  const payload = await httpClient('client-apps', {
    method: 'post',
    body: JSON.stringify(formData),
  });

  return payload;
}

function transformClientAppFormData(data: ConsumerClientAppInput) {
  return stripEmptyStringAttributes({
    name: data.name,
    email: data.email,
    icon: data.icon,
    url: data.url,
    feedback: data.feedback,
    allowedGrantTypes: splitLn(data.allowedGrantTypes),
    redirectUris: data.redirectUris?.map(o => o.url) ?? [],
    clientType: data.clientType,
    allowedOrigins: splitLn(data.allowedOrigins),
    allowedReferrers: splitLn(data.allowedReferrers),
    allowedIpAddresses: splitLn(data.allowedIpAddresses),
    // requestedScopes: data.requestedScopes?.length
    //   ? data.requestedScopes
    //   : undefined,
  });
}

export function transformClientAppToFormData(
  data: ConsumerClientAppClaim,
): ConsumerClientAppInput {
  return {
    name: data.name,
    email: data.email,
    icon: data.icon,
    url: data.url,
    feedback: data.feedback,
    // allowedGrantTypes: splitLn(data.allowedGrantTypes),
    redirectUris:
      data.redirectUris?.map(o => {
        return { url: o };
      }) ?? [],
    clientType: data.clientType,
    // allowedOrigins: splitLn(data.allowedOrigins),
    // allowedReferrers: splitLn(data.allowedReferrers),
    // allowedIpAddresses: splitLn(data.allowedIpAddresses),
    // requestedScopes: data.requestedScopes?.length
    //   ? data.requestedScopes
    //   : undefined,
  };
}

export async function getConsumerClientAppClaims(
  organizationId: string,
): Promise<ConsumerClientAppClaim[]> {
  const payload = await httpClient(
    `client-apps/dev-orgs/${encodeURIComponent(organizationId)}`,
  );

  return payload.items;
}

export async function updateConsumerClientApp(
  sk: string,
  data: ConsumerClientAppInput,
): Promise<ConsumerClientAppClaim> {
  return httpClient(`client-apps/${encodeURIComponent(sk)}`, {
    method: 'PATCH',
    body: JSON.stringify(transformClientAppFormData(data)),
  });
}

export async function deleteConsumerClientApp(sk: string) {
  return httpClient(`client-apps/${encodeURIComponent(sk)}`, {
    method: 'DELETE',
  });
}

export async function resetConsumerClientAppKey(
  sk: string,
): Promise<ConsumerClientAppClaim> {
  return httpClient(`client-apps/${encodeURIComponent(sk)}/reset-api-key`, {
    method: 'PATCH',
  });
}

export async function addContributorMiniApp(
  organizationId: string,
  data: ContributorMiniAppInput,
): Promise<ContributorMiniAppClaim> {
  const formData = {
    ...transformMiniAppFormData(data),
    pk: organizationId,
  };
  const payload = await httpClient('miniapps', {
    method: 'post',
    body: JSON.stringify(formData),
  });

  return payload;
}

function transformMiniAppFormData(data: ContributorMiniAppInput) {
  return stripEmptyStringAttributes({
    name: data.name,
    icon: data.icon,
    url: data.url,
    feedback: data.feedback,
    description: data.description,
    platforms: splitLn(data.platforms),
  });
}

export async function getContributorMiniAppClaims(
  organizationId: string,
): Promise<ContributorMiniAppClaim[]> {
  const payload = await httpClient(
    `miniapps/dev-orgs/${encodeURIComponent(organizationId)}`,
  );
  return payload.items;
}

export async function renameContributorMiniApp(
  sk: string,
  newName: string,
): Promise<ContributorMiniAppClaim> {
  return httpClient(`miniapps/${encodeURIComponent(sk)}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: newName,
    }),
  });
}

export async function deleteContributorMiniApp(sk: string) {
  return httpClient(`miniapps/${encodeURIComponent(sk)}`, {
    method: 'DELETE',
  });
}

export async function resetContributorMiniAppKey(
  sk: string,
): Promise<ContributorMiniAppClaim> {
  const payload = await httpClient(
    `miniapps/${encodeURIComponent(sk)}/reset-secret`,
    {
      method: 'PATCH',
    },
  );

  return payload;
}
