import config from 'config';
import { Mixpanel } from 'mixpanel';

import { useDispatch, useSelector } from 'reduxStore/hooks';
import { getCurrentOrganization } from 'reduxStore/organization/organization.slice';
import { getPlanState } from 'reduxStore/plan/plan.slice';
import {
  getPricingState,
  subscribePlan,
} from 'reduxStore/pricing/pricing.slice';
import { toastActions } from 'reduxStore/toast/toast.slice';

import useSignInUrl from 'hooks/useSignInUrl';

import { subscriptionResultUrl } from '../../constants';
import PriceCard from './priceCard';

import style from './pricing.module.scss';

export default function Pricing() {
  const signInUrl = useSignInUrl();
  const organization = useSelector(getCurrentOrganization);
  const { plans } = useSelector(getPlanState);
  const { loading } = useSelector(getPricingState);
  const { user } = useSelector(state => state.auth);
  const isSignedIn = Boolean(user);
  const { planDetail, pk } = organization || {};
  const dispatch = useDispatch();
  const sortedPlans = Array.from(plans).sort((a, b) => {
    if (b.sk === 'DEFAULT') {
      return 1;
    }

    return 0;
  });
  const [freePlan, paidPlan] = sortedPlans;

  const [freePlanQuotas, paidPlanQuotas] = sortedPlans.map(plan => {
    const rateLimits = plan.rateLimits
      .filter(k => !k.hide)
      .map(limit => {
        return {
          description: limit.label,
          value: limit.points,
        };
      });

    return [
      ...rateLimits,
      {
        description: 'Max client apps',
        value: plan.maxAPIKeys,
      },
      {
        description: 'Max marketplace miniapps',
        value: plan.maxMiniApps,
      },
    ];
  });

  return (
    <div className={style.pricing}>
      <h1>Select your tier</h1>
      <div className={style.cards}>
        <PriceCard
          activeTier={planDetail?.sk === 'DEFAULT'}
          className={style.card}
          name="Free tier"
          title=""
          variant="free"
          actionButton={
            !isSignedIn
              ? {
                  label: 'Get started',
                  onClick: () => (window.location.href = signInUrl),
                }
              : undefined
          }
        >
          <ul>
            <li>Requests will be rate limited</li>
            <li>5 production client apps</li>
            <li>Access to most API endpoints</li>
          </ul>
          <div className={style.table}>
            {freePlanQuotas?.map((quota, index) => (
              <div key={index}>
                <div>{quota.description}</div>
                <div>{quota.value}</div>
              </div>
            ))}
          </div>
        </PriceCard>
        <PriceCard
          activeTier={planDetail?.sk === 'PAID'}
          className={style.card}
          name="Paid tier"
          title=""
          variant="paid"
          actionButton={
            config.disablePaidPlan
              ? {
                  label: 'Coming Soon',
                  onClick: () => {},
                }
              : planDetail?.sk !== 'PAID'
              ? {
                  label: 'Get started',
                  disabled: loading,
                  onClick: async () => {
                    Mixpanel.track('dev-portal paid-tier-btn', {
                      button: 'Get started',
                    });
                    if (!isSignedIn) {
                      window.location.href = signInUrl;
                      return;
                    }
                    const paidPlan = plans.find(plan => plan.sk === 'PAID')!;
                    const redirectUri =
                      window.location.origin + subscriptionResultUrl;
                    const res = await dispatch(
                      subscribePlan({
                        planId: paidPlan.pk,
                        organizationId: pk,
                        redirectUri,
                      }),
                    ).unwrap();

                    try {
                      window.location.href = res.url;
                    } catch (err) {
                      dispatch(
                        toastActions.addToast({
                          title: 'Failed to subscribe',
                          status: 'error',
                        }),
                      );
                    }
                  },
                }
              : undefined
          }
        >
          <ul>
            <li>High rate limit</li>
            <li>Up to 500 production client apps</li>
            <li>Access to all available endpoints</li>
          </ul>
          <div className={style.table}>
            {paidPlanQuotas?.map((quota, index) => (
              <div key={index}>
                <div>{quota.description}</div>
                <div>{quota.value}</div>
              </div>
            ))}
          </div>
        </PriceCard>
        <PriceCard
          className={style.card}
          name="Enterprise tier"
          title=""
          variant="enterprise"
          actionButton={{
            label: 'Contact us',
            onClick: () => {
              Mixpanel.track('dev-portal enterprise-tier-btn', {
                button: 'Contact us',
              });
              // Redirect to mail creation page
              window.location.href = 'mailto:support+devportal@collab.land';
            },
          }}
        >
          <ul>
            <li>
              Custom solution for enterprise partners who need special
              consideration, or increased rate limits beyond what is offered in
              paid version. <br /> <br />Contact us for more information.
            </li>
          </ul>
        </PriceCard>
      </div>
    </div>
  );
}
